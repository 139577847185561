import React from 'react'
import styled from 'styled-components'
import { Button } from '@/style/components/Button'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'
import { cn } from '@/utils/cn'

export interface OptionsProps {
  value: string | number | boolean
  text: string
}

interface ConfiguratorPropertyProps {
  headline: string
  description: string | undefined
  value?: any
  options: OptionsProps[]
  onChange?: (value: any) => void
  style?: React.CSSProperties
  buttonStyle?: React.CSSProperties
  headlineStyle?: React.CSSProperties
}

const ConfiguratorProperty = ({
  headline,
  description,
  value,
  options,
  onChange,
  style = {},
  buttonStyle = {},
  headlineStyle = {},
}: ConfiguratorPropertyProps) => {
  return (
    <GroupWrapper style={style}>
      <QuestionTitle weight="medium" style={headlineStyle}>
        {headline}
      </QuestionTitle>

      {description && <Description>{description}</Description>}

      {(options ?? []).map((option) => (
        <Button
          style={buttonStyle}
          type="button"
          key={option.value.toString()}
          className={cn(
            option.value == value &&
              '!bg-fill-accent-tertiary !border-accent-500',
          )}
          appearance={option.value == value ? 'primary-outline' : 'whitebg'}
          onClick={() => {
            if (onChange) {
              value = option.value
              onChange(option.value)
            }
          }}
        >
          {option.text}
        </Button>
      ))}
    </GroupWrapper>
  )
}

const GroupWrapper = styled.section`
  text-align: center;
  margin: 3rem 0;

  div {
    padding-bottom: 1rem;
  }
`
const Description = styled(Text.ms).attrs({ as: 'p' })`
  margin-bottom: 0.5rem;
  color: #6c6d6f;
`

const QuestionTitle = styled(Text.h3)`
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.3rem;

  ${media.xs} {
    font-size: 0.875rem;
  }

  ${media.sm} {
    font-size: 1rem;
  }
`

export default ConfiguratorProperty
