import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import { Button } from '@/components/Button'
import { LeftArrow, RightArrow } from '@/style/components/Arrow/Arrow'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'
import { cn } from '@/utils/cn'

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 110px);

  ${media.sm} {
    width: 700px;
    height: 698px;
    margin-top: 0px;
    border-radius: 13px;
  }

  ${media.md} {
    width: 860px;
    height: 698px;
    margin-top: 0px;
    border-radius: 13px;
  }
`

const StyledRow = styled(Row)`
  height: 100%;
  align-content: stretch;
  margin: 0;
  display: block;

  ${media.sm} {
    display: flex !important;
  }
`

const FormCol = styled(Col)`
  display: flex !important;
  flex-direction: column;
  padding: 0;

  ${media.sm} {
    height: 650px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const FormWrapper = styled.div`
  margin-left: 6%;
  margin-bottom: 1rem;
  width: 88%;
  text-align: center;
`

const MobileHeader = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  background: white;
  border-radius: 13px;

  ${media.sm} {
    display: block;
    padding-top: 14px;
    height: 48px;
  }

  ${media.md} {
    display: none;
  }
`

const NavHeadline = styled(Text.lg)`
  font-size: 1.75rem;
  word-wrap: break-word !important;
`

const NavLinkText = styled(Text.md)`
  word-wrap: break-word !important;
  hyphens: none;
`

const BackButton = styled.span`
  position: absolute;
  top: 14px;
  left: 20px;
  cursor: pointer;
  z-index: 4;

  ${media.sm} {
    top: 15px;
  }
`

const MobileNavbarTitle = styled(Text.lg)`
  display: none;

  ${media.sm} {
    display: block;
  }
`

interface AuthDialogProps {
  content: JSX.Element
  sideIcon?: JSX.Element
  fixedMobileFooter?: boolean
  navBar: {
    title: string
    link?: {
      description: string
      text: string
      onClick: () => void
    }
    backButton?: () => void
  }
}

const AuthDialog: React.FC<AuthDialogProps> = (props) => {
  const { content, navBar, sideIcon, fixedMobileFooter } = props

  return (
    <Wrapper>
      <StyledRow>
        <MobileHeader>
          {navBar.backButton && (
            <BackButton onClick={navBar.backButton}>
              <LeftArrow fontSize="small" />
            </BackButton>
          )}
          <MobileNavbarTitle weight="semibold">
            {navBar.title}
          </MobileNavbarTitle>
        </MobileHeader>
        <div className="hidden md:flex w-1/3 flex-col relative text-on-primary bg-fill-brand-primary hyphens-auto pb-0 pt-[200px] px-9">
          <NavHeadline weight="semibold" as="h2" lang="de">
            {navBar.title}
          </NavHeadline>
          <hr className="my-6 border border-accent" />
          {navBar.link && (
            <NavLinkText as="p">{navBar.link.description}</NavLinkText>
          )}
          {navBar.link && (
            <Button
              className="text-md flex flex-row justify-start px-0 items-center"
              variant="ghost"
              onClick={navBar.link.onClick}
            >
              {navBar.link.text}
              <RightArrow className="text-accent-500" fontSize="small" />
            </Button>
          )}
          {sideIcon}
        </div>
        <FormCol md={8} sm={12} xs={12}>
          <FormWrapper>{content}</FormWrapper>
          <div
            className={cn(
              'md:hidden text-on-primary bg-fill-brand-primary h-[90px] w-full pt-4 text-center mt-auto bottom-0',
              fixedMobileFooter && 'fixed',
            )}
          >
            {navBar.link && (
              <div className="mt-auto">
                <Text.md as="p">{navBar.link.description}</Text.md>
                <Button
                  className="font-semibold text-md my-2"
                  variant="link"
                  onClick={navBar.link.onClick}
                >
                  {navBar.link.text}
                </Button>
              </div>
            )}
          </div>
        </FormCol>
      </StyledRow>
    </Wrapper>
  )
}

export default AuthDialog
